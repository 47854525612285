import { render, staticRenderFns } from "./ChipsGroup.vue?vue&type=template&id=62758160&scoped=true&"
import script from "./ChipsGroup.vue?vue&type=script&lang=js&"
export * from "./ChipsGroup.vue?vue&type=script&lang=js&"
import style0 from "./ChipsGroup.vue?vue&type=style&index=0&id=62758160&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62758160",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VChip,VChipGroup,VIcon})
