<template>
	<v-expansion-panels class="home-expansion" v-model="active">
		<v-expansion-panel
			v-for="(q, i) of questions"
			:key="q._id"
			class="mb-6"
			:class="{ 'green lighten-5': active === i }"
			@change="$emit('update:active', active)"
		>
			<v-expansion-panel-header :class="{ 'border-bottom': active === i }">
				<div class="d-flex flex-column">
					<span class="grey--text lighten-2">{{ q.question._id }}</span>
					<h4 v-if="active !== i" class="mb-0 mt-2">
						{{ q.question.name }}
					</h4>
				</div>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<h3 class="mt-3">{{ q.question.name }}</h3>
				<span>
					{{ q.name }}
				</span>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	name: "HomeExpansion",

	props: {
		active: {
			type: Number,
			required: true,
		},
		questions: {
			type: Array,
			required: true,
		},
	},

	created() {
		console.log("left", this.questions);
	},
};
</script>

<style></style>
