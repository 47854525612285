<template>
	<div class="home">
		<!-- Верхняя -->
		<div class="d-flex justify-space-between mb-6">
			<!-- Название КСК -->
			<h2 class="mr-5">
				{{ ksk || "Выберите адрес для отображения вопросов" }}
			</h2>
			<!-- Поиск по адресу -->
			<v-autocomplete
				@click="edit = false"
				v-model="params.houses_ids"
				class="border-all mr-5"
				placeholder="Адрес"
				prepend-inner-icon="mdi-map-marker"
				hide-details
				dense
				solo
				flat
				return-object
				clearable
				:loading="!cHouses.length"
				:filter="addressFilter"
				:items="cHouses"
				item-text="apartment_address"
				item-value="_id"
			>
			</v-autocomplete>

			<!-- Кнопка -->
			<v-btn
				color="primary"
				depressed
				class="text-capitalize subtitle-2"
				@click="showCreateModal = true"
			>
				Создать
			</v-btn>
		</div>

		<main>
			<v-row>
				<v-col cols="12" sm="8">
					<div v-if="ksk">
						<div class="mb-5 d-flex justify-end">
							<v-btn color="success" v-if="!edit" @click="edit = true">Редактировать</v-btn>
							<v-btn color="grey" v-else @click="edit = false">Отмена</v-btn>
							<v-btn color="success" class="ml-3" @click="saveDesc" :disabled="!edit">Сохранить</v-btn>
						</div>
							<v-card v-if="!edit"
								elevation="2"
								class="mx-auto pa-5 card-desc"
							>
								<v-virtual-scroll
									v-if="description"
									height="700"
									item-height="64"
									v-html="description"
								>
								</v-virtual-scroll>
								<v-alert v-else type="info" text>Описание пусто</v-alert>
							</v-card>
							<VueEditor v-else useCustomImageHandler v-model="description" />
						<!-- <v-textarea
							v-model="description"
							solo
							label="Введите текст"
							hint="Hint text"
							>
						</v-textarea> -->
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<!-- Поиск по ключевым словам -->
					<v-text-field
						v-model="params.name"
						class="border-all ma-3"
						placeholder="Поиск по ключевым словам"
						prepend-inner-icon="mdi-magnify"
						hide-details
						dense
						solo
						flat
						clearable
						:loading="loading"
					>
					</v-text-field>

					<!-- Категория -->
					<v-autocomplete
						v-model="params.category_ids"
						class="border-all ma-3"
						placeholder="Категория"
						prepend-inner-icon="mdi-filter"
						hide-details
						dense
						solo
						flat
						clearable
						:loading="loading"
						:items="catalog.request_categories"
						item-text="name"
						item-value="id"
					>
					</v-autocomplete>
					<v-row v-if="!questions.length">
						<v-col>
							<p class="grey--text">Нет доступных вопросов</p>
						</v-col>
					</v-row>

					<v-row v-else>
						<v-col cols="12">
							<!-- <HomeExpansion :active="left" :questions="leftQuestions" /> -->
							<v-expansion-panels v-model="left">
								<v-expansion-panel
									v-for="(q, i) of questions"
									:key="i"
									class="mb-6"
									:class="{ 'green lighten-5': left === i }"
								>
									<v-expansion-panel-header
										:class="{ 'border-bottom': left === i }"
									>
										<div class="d-flex flex-column">
											<span class="grey--text lighten-2">{{
												q.category.name
											}}</span>
											<h4 v-if="left !== i" class="mb-0 mt-2">
												{{ q.name }}
											</h4>
										</div>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<h3 class="mt-3">{{ q.name }}</h3>
										<span v-if="q.answers.length">{{ q.answers[0].name }}</span>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			
		</main>

		<RequsitionsTableModal
			v-if="showCreateModal"
			:category-id="params.category_ids"
			:house-id="params.houses_ids"
			:is-dispatcher="true"
			@close-modal="showCreateModal = false"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import HomeExpansion from "@/components/dispatcher/HomeExpansion";
import RequsitionsTableModal from "@/components/RequsitionsTableModal";

export default {
	name: "Home",

	components: { HomeExpansion, RequsitionsTableModal, VueEditor },

	data() {
		return {
			left: -1,
			right: -1,
			loading: false,
			edit: false,
			ksk: null,
			ksk_id: null,
			description: null,
			showCreateModal: false,
			timer: null,
			params: {
				category_ids: this.$route.params.category_ids || null,
				houses_ids: this.$route.params.houses_ids || null,
				name: this.$route.params.name || null,
			},
			questions: [],
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			housesDispatcher: "dispatcher/GET_HOUSES",
			houses: "ksk/GET_HOUSES",
			base_role: "auth/GET_USER_ROLE",
		}),

		questionsHalfLength() {
			const l = this.questions.length;
			return Math.floor(l / 2) + (l % 2);
		},

		leftQuestions() {
			console.log(this.questions.slice(0, this.questionsHalfLength));
			return this.questions.slice(0, this.questionsHalfLength);
		},

		rightQuestions() {
			return this.questions.slice(this.questionsHalfLength);
		},

		cHouses() {
			return this.base_role === "ksk_dispatcher"
				? this.houses
				: this.housesDispatcher;
		},
	},

	watch: {
		params: {
			handler() {
				const query = {};
				for (const key in this.params) {
					if (this.params[key] === "") this.params[key] = null;
					if (this.params[key] !== null) query[key] = this.params[key];
				}
				this.$router.replace({ query });
				setTimeout(() => {
					this.loadData();
				}, 500);
				
			},
			deep: true,
		},
	},

	methods: {
		async saveDesc() {
			try {
				let params = {description: this.description, _method: 'patch'}
				const data = params.toFormData();
				await this.$api.dispatcher.save_description(this.ksk_id, data);
			} catch (error) {
				this.$root.snackbar.show({ color: "error", message: "Произошла ошибка. Повторите еще раз." });
			} finally {
				this.$root.snackbar.show({ color: "success", message: "Изменения сохранены" });
				this.edit = false
			}
		},
		// async loadDesc() {
		// 	try {
		// 		this.loading = true
		// 		const response = await this.$api.dispatcher.load_description(this.ksk_id);
		// 		this.description = response.ksk_description
		// 		console.log('res', response)
		// 	} catch (error) {
		// 		console.error(error);
		// 	} finally {
		// 		this.loading = false;
		// 	}
		// },
		// async loadAnswers() {
		// 	try {
		// 		for (const q of this.questions) {
		// 			const params = { question_id: q._id, houses_ids: q.houses_ids || null };
		// 			const res = await this.$api.ksk.load_answer(params);

		// 			if (!q.houses_ids) q.houses_ids = null;

		// 			q.answer = { name: "" };

		// 			if (res.error) continue;

		// 			q.answer = res;
		// 		}
		// 	} catch (error) {
		// 		throw error;
		// 	}
		// },

		async loadData() {
			try {
				this.loading = true;
				const params = {...this.params}
				if(this.params.houses_ids) {
					params.company_ids = [this.params.houses_ids.company_id];
					delete params.houses_ids
				}
				if (this.params.category_ids && !Array.isArray(this.params.category_ids)) {
					params.category_ids = [this.params.category_ids];
				}
				
				const res = await this.$api.dispatcher.load_questions(params);
				if(res.company) {
					this.ksk = res.company[0].name
					this.ksk_id = res.company[0].id
					if(res.company[0].dispatcher_info) {
						this.description = res.company[0].dispatcher_info.description
					}
				}
				console.log('ksk', res)
				
				if (!this.params.houses_ids) this.questions = [];
				else {
					const { records } = res;
					console.log('records',records)
					// Filter questions without answer
					this.questions = records
					console.log('quests', this.questions)
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
	},

	created() {
		this.$store.dispatch("dispatcher/load_houses");
	},

	mounted() {
		this.loadData();
	},
};
</script>



<style lang="scss">
.home {
	padding: 20px;
	background: #fff;
	min-height: 90vh;
}
.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}
.ql-toolbar .ql-formats .ql-image {
	display: none !important;
} 
.ql-toolbar .ql-formats .ql-video {
	display: none !important;
} 
.card-desc {
	background: #fff;
}
</style>
